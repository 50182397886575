.card {
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #eee;

  .card-img {
    img {
      width: 100%;
      border-radius: 9px 9px 0px 0px;
    }
  }

  .card-content {
    flex: 1;
    justify-content: space-between;
    padding: 15px;
    display: flex;
    flex-flow: column;
    border-radius: 0px 0px 10px 10px;

    h2 {
      margin: 0px;
      font-size: 1.2em;
      flex: 1;
    }

    span {
      margin: 10px 0px;
    }

    button {
      margin-top: 10px;
      cursor: pointer;
      text-transform: uppercase;
      padding: 10px;
      border-radius: 5px;
      color: var(--purple-color);
      font-weight: bold;
      border: 1px solid var(--purple-color);
      background: white;
    }

    button:hover {
      opacity: 1;
      background-color: var(--purple-color);
      color: white;
    }

    .card-content-job {
      display: flex;
      flex-flow: column;
      margin-top: 10px;

      span {
        margin: 0px;
        padding-bottom: 5px;
      }

      .card-content-job-type {
        color: var(--purple-color);
        font-weight: 500;
      }

      .card-content-job-benefits {
        margin-top: 5px;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        gap: 5px;

        span {
          background-color: #eee;
          padding: 4px 8px;
          border-radius: 10px;
          font-size: 0.9em;
        }
      }
    }
  }
}

// .card:hover {
//   box-shadow: 0px 0px 10px #ddd;
// }