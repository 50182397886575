.login {
  .login-form {
    margin-top: 20px;

    .login-form-item {
      display: flex;
      flex-flow: column;
      margin-bottom: 10px;

      label {
        margin-bottom: 5px;
      }

      input {
        border: none;
        padding: 12px;
        font-size: 1em;
        border-radius: 10px;
        outline-color: var(--purple-color);
        border: 1px solid var(--border-color);
      }

      input:hover {
        background-color: var(--input-hover-color);
      }
    }

    button {
      border: none;
      width: 100%;
      padding: 15px;
      background-color: transparent;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 10px;
      cursor: pointer;
      background-color: var(--purple-color);
      color: white;
      margin-top: 10px;
    }
  }

  .register-link {
    display: flex;
    justify-content: center;
    padding: 15px;

    a:hover {
      color: var(--purple-color);
    }
  }
}