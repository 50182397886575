.searchbar {
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 0px 2px var(--grey-color);

  .searchbar-job-input,
  .searchbar-location-input {
    flex: 5;
    display: flex;
    flex-flow: column;
    padding: 12px 20px;
    border-right: 1px solid var(--border-color);
    border-radius: 9px 0px 0px 9px;

    span {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.8em;
    }

    input {
      margin-top: 10px;
      margin-bottom: 3px;
      flex: 1;
      border: none;
      font-size: 1em;
      background-color: transparent;
      outline: none;
      padding: 0;
      color: var(--dark-grey-color)
    }
  }

  .searchbar-location-input {
    border-radius: 0px;
  }

  .searchbar-job-input:hover,
  .searchbar-location-input:hover {
    background-color: var(--input-hover-color);
  }

  // .searchbar-location-input {
  //   flex: 5;
  //   display: flex;
  //   border-right: 1px solid var(--border-color);

  //   input {
  //     border: none;
  //     flex: 1;
  //     padding: 20px;
  //     font-size: 1em;
  //     outline-color: var(--purple-color);
  //   }

  //   input:hover {
  //     background-color: var(--input-hover-color);
  //   }
  // }

  .searchbar-btn {
    flex: 1;
    display: flex;
    justify-content: center;

    button {
      border: none;
      flex: 1;
      background-color: transparent;
      // color: var(--purple-color);
      font-size: 1.2em;
      // border-radius: 5px;
      // margin: 5px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }

    button:hover {
      opacity: 1;
      color: var(--purple-color);
      transform: scale(1.2);
      // color: white;
    }
  }
}

@media screen and (max-width: 500px) {
  .searchbar {
    flex-flow: column;

    .searchbar-job-input {
      flex: 1;
      border: none;
      border-bottom: 1px solid var(--border-color);
      border-radius: 9px 9px 0px 0px;
      padding: 10px;
    }

    .searchbar-location-input {
      flex: 1;
      border: none;
      border-bottom: 1px solid var(--border-color);
      border-radius: 0px;
      padding: 10px;
    }

    .searchbar-btn {
      padding: 10px;
      flex: 1;
    }
  }
}