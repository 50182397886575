.company {
  display: flex;
  flex-flow: column;

  .company-header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }

  .company-content {
    display: flex;
    flex-flow: column;

    .company-content-benefits-title {
      // font-weight: 600;
    }

    .company-content-benefits {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: 5px;

      .company-content-benefits-item {
        background-color: var(--grey-color);
        padding: 5px 10px;
        border-radius: 10px;
      }
    }
  }

  .company-jobs {
    margin-top: 20px;

    .card-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 15px;
      grid-auto-rows: minmax(100px, auto);
    }
  }
}