.container {
  display: flex;
  flex-flow: column;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}

:root {
  --purple-color: #6e30ff;
  --green-color: #21c771;
  --border-color: #ccc;
  --grey-color: #eee;
  --dark-grey-color: #777;
  --input-hover-color: #f8f8f8;
  --grey-blue-color: #7d8096;
  --red-color: #e52020;
}

h1,
h2,
h3,
h4,
label,
button,
span,
a {
  color: #1e1e1e;
}

button:hover {
  opacity: 0.9;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;

}

.flex-3 {
  flex: 3;
}

.loader {
  width: 48px;
  height: 48px;
  border: 2px solid #eee;
  border-bottom-color: var(--purple-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-container {
  height: calc(100vh - 100px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Checkbox style
[type=checkbox] {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--purple-color);
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #FFFFFF;
  transition: background 300ms;
  cursor: pointer;
}


/* Pseudo element for check styling */

[type=checkbox]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}


/* Checked */

[type=checkbox]:checked {
  background-color: currentcolor;
}

[type=checkbox]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

/* IE */

[type=checkbox]::-ms-check {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}

[type=checkbox]:checked::-ms-check {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

// Select
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.select-container {
  position: relative;
  display: inline;
}

.select-container:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}

.select-container:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  top: 1.2em;
  right: 1.2em;
  border-top: 8px solid #4b6584;
}

// Editor
.ql-toolbar.ql-snow {
  border-radius: 10px 10px 0px 0px;
}

.ql-container.ql-snow {
  border-radius: 0px 0px 10px 10px;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 15px;
  }

  h2 {
    font-size: 1.2em;
  }

  [type=checkbox] {
    width: 1.3rem;
    height: 1.3rem;
  }
}