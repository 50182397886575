.home {
  .home-infos {
    display: flex;
    flex-flow: column;
    padding: 50px;
    align-items: center;
    justify-content: center;

    span {
      margin-bottom: 30px;
      font-size: 2em;
    }

    button {
      background-color: var(--purple-color);
      padding: 15px;
      text-transform: uppercase;
      color: white;
      font-weight: bold;
      border: none;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .home-searchbar {
    display: flex;
    margin: 0px 50px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    box-shadow: 0px 0px 2px var(--grey-color);

    .home-searchbar-job-input {
      flex: 5;
      display: flex;
      border-right: 1px solid var(--border-color);

      input {
        flex: 1;
        border: none;
        padding: 20px;
        font-size: 1em;
        border-radius: 9px 0px 0px 9px;
        outline-color: var(--purple-color);
      }

      input:hover {
        background-color: var(--input-hover-color);
      }
    }

    .home-searchbar-location-input {
      flex: 5;
      display: flex;
      border-right: 1px solid var(--border-color);

      input {
        border: none;
        flex: 1;
        padding: 20px;
        font-size: 1em;
        outline-color: var(--purple-color);
      }

      input:hover {
        background-color: var(--input-hover-color);
      }
    }

    .home-searchbar-btn {
      flex: 1;
      display: flex;
      justify-content: center;

      button {
        border: none;
        flex: 1;
        background-color: transparent;
        // color: var(--purple-color);
        font-size: 1.2em;
        // border-radius: 5px;
        // margin: 5px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      button:hover {
        opacity: 1;
        color: var(--purple-color);
        transform: scale(1.2);
        // color: white;
      }
    }
  }

  .home-companies {
    margin-top: 50px;

    h1 {
      font-size: 2em;
    }
  }

  .home-last-jobs {
    margin-top: 50px;

    h1 {
      font-size: 2em;
    }
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    grid-auto-rows: minmax(100px, auto);
  }

  .home-footer {
    margin-top: 20px;
    background: #f8f8f8;

    .footer-container {
      display: flex;
      flex-flow: row;
      max-width: 1000px;
      margin: auto;
      padding: 20px;
    }

    .home-footer-left {
      flex: 2;
      display: flex;
      align-self: center;
      justify-content: flex-start;

      span {
        margin-right: 20px;
        font-size: 1em;
        font-weight: 500;
      }

      span:hover {
        color: var(--purple-color);
        cursor: pointer;
      }
    }

    .home-footer-right {
      flex: 1;
      display: flex;
      align-self: center;
      justify-content: flex-end;

      span {
        font-size: 1em;
        font-weight: 400;
        margin-right: 5px;
      }

      div {
        display: flex;
        flex-flow: row;

        svg {
          font-size: 1.2em;
          margin: 0px 5px;
        }

        a:hover {
          color: var(--purple-color);
        }
      }
    }
  }

  .loader-container {
    height: auto !important;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
}

@media screen and (max-width: 500px) {
  :host h1 {
    font-size: 1.6em !important;
    margin-bottom: 20px !important;
  }

  .home-infos {
    padding: 30px !important;

    span {
      margin-bottom: 20px !important;
      font-size: 1.6em !important;
      text-align: center;
    }
  }

  .home-companies {
    margin-top: 20px !important;
  }

  .home-last-jobs {
    margin-top: 20px !important;
  }

  .footer-container {
    padding: 15px !important;
    flex-flow: column !important;

    .home-footer-left {
      flex-flow: column;
      align-items: center !important;
      margin-bottom: 20px;

      span {
        margin-bottom: 5px;
      }
    }

    .home-footer-right {
      align-items: center !important;
    }
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
}