.register {
  .register-account-type {
    display: flex;
    flex-flow: row;
    gap: 10px;

    button {
      background-color: var(--grey-color);
      padding: 15px 50px;
      text-transform: uppercase;
      border: none;
      border-radius: 10px;
      cursor: pointer;
    }

    svg {
      margin-right: 5px;
    }

    .selected {
      background-color: var(--purple-color);
      color: white;
      font-weight: bold;
    }
  }

  .register-form {
    margin-top: 20px;

    .register-form-row {
      display: flex;
      flex-flow: wrap;
      gap: 10px;
    }

    .register-form-item {
      display: flex;
      flex-flow: column;
      margin-bottom: 10px;

      label {
        margin-bottom: 5px;
      }

      input {
        border: none;
        padding: 12px;
        font-size: 1em;
        border-radius: 10px;
        outline-color: var(--purple-color);
        border: 1px solid var(--border-color);
      }

      input:hover {
        background-color: var(--input-hover-color);
      }
    }

    .benefits-list {
      margin-top: 5px;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: 5px;

      span {
        background-color: #eee;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .input-error {
    padding: 5px;
    font-size: 0.8em;
    color: var(--red-color);
  }

  .submit-btn {
    background-color: var(--purple-color);
    width: 100%;
    padding: 15px;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    color: white;
  }

  .submit-btn:hover {
    opacity: 0.9;
  }

  .submit-btn:disabled {
    opacity: 0.6;
  }
}

@media screen and (max-width: 500px) {
  .register-account-type button {
    padding: 15px !important;
  }
}