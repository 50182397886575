.dashboard {
  .fab-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;

    button {
      padding: 15px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      text-transform: uppercase;
      background-color: var(--purple-color);
      color: white;
      font-weight: bold;
    }
  }

  .section-header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    .section-header-left {
      display: flex;
      flex-flow: row;
      align-items: center;
      gap: 10px;

      button {
        padding: 8px;
        border: none;
        border-radius: 10px;
        cursor: pointer;

        svg {
          margin-right: 2px;
        }
      }

      .edit-btn {
        background-color: #eee;
      }

      .edit-btn:hover {
        background-color: #e0e0e0;
      }

      .save-btn {
        background-color: var(--purple-color);
        color: white;
        // text-transform: uppercase;
        font-weight: bold;
        font-size: 0.8em;
      }
    }

    .section-header-right {
      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 1em;
      }

      button:hover {
        opacity: 1;
        color: var(--purple-color)
      }
    }
  }

  .company-infos {
    display: flex;
    flex-flow: column;

    .company-infos-content {
      display: flex;
      flex-flow: column;

      .company-infos-content-resume {
        display: flex;
        flex-flow: column;

        .infos-resume-row {
          display: flex;
          flex-flow: row;
          gap: 10px;
        }

        .infos-resume-item {
          display: flex;
          flex-flow: column;
        }
      }
    }
  }

  .company-benefits {
    display: flex;
    flex-flow: column;

    .company-benefits-content {
      display: flex;
      flex-flow: column;

      .company-benefits-list {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        gap: 5px;

        .company-benefits-list-item {
          background-color: var(--grey-color);
          padding: 6px;
          border-radius: 10px;
          display: flex;
          flex-flow: row;
          align-items: center;
          gap: 5px;

          svg {
            cursor: pointer;
            color: var(--dark-grey-color);
          }

          svg:hover {
            color: var(--red-color);
          }
        }

        button {
          padding: 8px 12px;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          border: none
        }

        .edit-btn {
          background-color: transparent;
          color: var(--dark-grey-color);
        }

        .edit-btn:hover {
          opacity: 1;
          color: #1e1e1e;
        }

        .save-btn {
          background-color: var(--purple-color);
          color: white;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.8em;
        }
      }

      .company-benefits-edition-list {
        margin-top: 10px;
        display: flex;
        flex-flow: wrap;
        gap: 5px;

        .company-benefits-edition-list-item {
          cursor: pointer;
          padding: 6px;
          border: 1px solid #cccccc;
          border-radius: 10px;
        }

        .company-benefits-edition-list-item:hover {
          background-color: var(--input-hover-color);
        }

        .selected {
          border-color: var(--purple-color);
          // font-weight: bold;
          color: var(--purple-color);
        }

        .selected:hover {
          opacity: 0.9;
        }
      }
    }
  }

  .company-jobs {
    .company-jobs-list {
      display: flex;
      flex-flow: column;
      gap: 15px;

      .company-jobs-list-item {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        padding: 10px;
        font-size: 1em;
        border-radius: 10px;
        // border: 1px solid var(--border-color);
        box-shadow: 0px 0px 10px #eee;
        // cursor: pointer;
        align-items: center;

        .job-content-title {
          font-weight: bold;
        }

        .job-content-infos {
          display: flex;
          flex-flow: column;
          gap: 5px;

          .job-content-infos-bottom {
            display: flex;
            flex-flow: row;
            gap: 10px;

            .job-content-infos-contract-type {
              color: var(--purple-color);
              font-weight: 600;
            }
          }
        }

        .job-content-actions {
          display: flex;
          flex-flow: row;
          gap: 5px;

          button {
            cursor: pointer;
            padding: 10px;
            border-radius: 5px;
            background: transparent;
            color: var(--dark-grey-color);
            border: none;
          }

          .default-btn:hover {
            opacity: 1;
            // background-color: var(--grey-blue-color);
            color: #1e1e1e;
          }

          .delete-btn {
            color: #e52020;
          }

          .delete-btn:hover {
            opacity: 1;
            background-color: #e52020;
            color: white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  // .edit-btn-text {
  //   display: none !important;
  // }

  .fab-container {
    position: fixed;
    bottom: 10px !important;
    right: 10px !important;
  }

  .company-jobs {
    margin-bottom: 50px;

    .company-jobs-list-item {
      flex-flow: column !important;

      .job-content-infos {
        gap: 10px !important;

        .job-content-infos-bottom {
          flex-flow: column !important;
          gap: 5px !important;
        }
      }

      .job-content-actions {
        margin-top: 5px;
      }
    }
  }
}