.search {
  .search-bar {
    display: flex;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    box-shadow: 0px 0px 2px var(--grey-color);

    .search-bar-job-input {
      flex: 5;
      display: flex;
      border-right: 1px solid var(--border-color);

      input {
        flex: 1;
        border: none;
        padding: 20px;
        font-size: 1em;
        border-radius: 9px 0px 0px 9px;
        outline-color: var(--purple-color);
      }

      input:hover {
        background-color: #f3f3f3;
      }
    }

    .search-bar-location-input {
      flex: 5;
      display: flex;
      border-right: 1px solid var(--border-color);

      input {
        border: none;
        flex: 1;
        padding: 20px;
        font-size: 1em;
        outline-color: var(--purple-color);
      }

      input:hover {
        background-color: #f3f3f3;
      }
    }

    .search-bar-btn {
      flex: 1;
      display: flex;
      justify-content: center;

      button {
        border: none;
        flex: 1;
        background-color: transparent;
        // color: var(--purple-color);
        font-size: 1.2em;
        border-radius: 5px;
        margin: 5px;
        cursor: pointer;
      }

      button:hover {
        opacity: 1;
        background-color: var(--purple-color);
        color: white;
      }
    }
  }

  .search-results {
    margin-top: 30px;

    h1 {
      font-size: 1.2em;
      font-weight: 400;
      color: var(--dark-grey-color)
    }

    b {
      font-weight: bold;
    }

    .search-results-content {
      display: flex;
      flex-flow: row;
      gap: 10px;

      .search-results-content-filters {
        flex: 1;
        display: flex;
        flex-flow: column;
        margin-bottom: 20px;

        .contract-type-checkbox {
          display: flex;
          align-items: center;
          justify-content: left;
          gap: 5px;
          cursor: pointer;
        }

        .benefits-list {
          margin-top: 5px;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          gap: 5px;

          span {
            background-color: var(--grey-color);
            padding: 5px 10px;
            border-radius: 10px;
            cursor: pointer;
          }

          .selected {
            background-color: var(--purple-color);
            color: white;
            // font-weight: bold;
          }
        }
      }

      .search-results-content-list {
        flex: 3;
        display: flex;
        flex-flow: column;
        gap: 15px;

        .list-item {
          display: flex;
          flex-flow: row;
          border-radius: 10px;
          box-shadow: 0px 0px 10px var(--grey-color);
          padding: 15px;
          align-items: center;

          h2 {
            font-size: 1.2em;
            margin-top: 0;
          }

          .list-item-description {
            flex: 9;
            display: flex;
            flex-flow: column;

            span {
              margin-bottom: 5px;
            }


            .list-item-type {
              color: var(--purple-color);
              font-weight: 500;
            }

            .list-item-benefits {
              margin-top: 5px;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              gap: 5px;

              span {
                background-color: #eee;
                padding: 4px 8px;
                border-radius: 10px;
                font-size: 0.9em;
                margin-bottom: 0px;
              }
            }
          }

          .list-item-action {
            flex: 1;

            button {
              cursor: pointer;
              text-transform: uppercase;
              padding: 10px;
              border-radius: 5px;
              color: var(--purple-color);
              font-weight: bold;
              border: 1px solid var(--purple-color);
              background: white;
            }

            button:hover {
              opacity: 1;
              background-color: var(--purple-color);
              color: white;
            }
          }
        }


        // .list-item:hover {
        //   box-shadow: 0px 0px 10px #ddd;
        // }
      }
    }
  }
}

@media screen and (max-width: 500px) {

  h1,
  h2 {
    margin: 10px 0px;
  }

  .search-results {
    margin-top: 10px !important;

    .search-results-content {
      flex-flow: column !important;

      .search-results-content-list {
        .list-item {
          flex-flow: column !important;

          .list-item-description {
            width: 100%;

          }

          .list-item-action {
            width: 100%;
            display: flex;
            flex-flow: column;
            margin-top: 10px;
          }
        }
      }
    }
  }
}