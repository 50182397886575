.job {
  display: flex;
  flex-flow: column;

  .job-header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    a {
      background-color: var(--purple-color);
      padding: 15px 20px;
      text-transform: uppercase;
      border: none;
      border-radius: 10px;
      font-weight: bold;
      cursor: pointer;
      color: white;
      text-decoration: none;
      font-size: 0.8em;

      svg {
        margin-left: 3px;
      }
    }
  }

  .job-content {
    display: flex;
    flex-flow: column;

    .job-content-company {
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
      align-self: flex-start;
    }

    .job-content-company:hover {
      color: var(--dark-grey-color);
    }

    .job-content-infos {
      margin-top: 10px;
      display: flex;
      flex-flow: row;
      gap: 10px;

      .job-content-infos-contract-type {
        color: var(--purple-color);
        font-weight: 600;
      }
    }

    .job-content-benefits-title {
      font-weight: 600;
    }

    .job-content-benefits {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: 5px;

      .job-content-benefits-item {
        background-color: var(--grey-color);
        padding: 5px 10px;
        border-radius: 10px;
      }
    }

    .job-content-editor {
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      border: 1px solid var(--border-color);
    }

    .job-content-ref {
      margin: 10px 0px;

      span {
        font-size: 0.8em;
        color: var(--dark-grey-color);
      }
    }
  }
}

@media screen and (max-width: 500px) {

  .job-content-infos {
    flex-flow: column !important;
    gap: 5px !important;
  }

  .job-header a {
    padding: 10px !important;
    text-align: center;

    svg {
      margin-left: 0px !important;
    }
  }
}