.job-creation {
  .job-creation-form {
    .job-creation-form-row {
      display: flex;
      flex-flow: row;
      gap: 10px;
    }

    .job-creation-form-item {
      display: flex;
      flex-flow: column;
      margin-bottom: 12px;

      label {
        margin-bottom: 8px;
      }

      input[type="text"],
      select {
        border: none;
        padding: 12px;
        font-size: 1em;
        border-radius: 10px;
        outline-color: var(--purple-color);
        border: 1px solid var(--border-color);
      }

      input[type="text"]:hover,
      select:hover {
        background-color: var(--input-hover-color);
      }
    }

    .generate-description-btn {
      background-color: var(--purple-color);
      padding: 10px;
      align-self: flex-start;
      font-size: 0.9em;
      border: none;
      border-radius: 10px;
      margin-top: 10px;
      cursor: pointer;
      color: white;
    }

    .contract-type-checkbox {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 5px;
      cursor: pointer;
    }

    .benefits-list {
      margin-top: 10px;
      display: flex;
      flex-flow: wrap;
      gap: 5px;

      .benefits-list-item {
        cursor: pointer;
        padding: 6px;
        border: 1px solid #cccccc;
        border-radius: 10px;
      }

      .benefits-list-item:hover {
        background-color: var(--input-hover-color);
      }

      .selected {
        border-color: var(--purple-color);
        color: var(--purple-color);
      }

      .selected:hover {
        opacity: 0.9;
      }
    }
  }

  .input-error {
    padding: 5px;
    font-size: 0.8em;
    color: var(--red-color);
  }

  .submit-btn {
    background-color: var(--purple-color);
    width: 100%;
    padding: 15px;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    color: white;
  }

  .submit-btn:hover {
    opacity: 0.9;
  }

  .submit-btn:disabled {
    opacity: 0.6;
  }
}