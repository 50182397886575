.navbar {
  display: flex;
  flex-flow: row;
  align-items: center;

  .navbar-logo {
    font-weight: bold;
    cursor: pointer;
  }

  .navbar-menu {
    flex: 1;
    place-content: flex-end;
    display: flex;
    flex-flow: row;
    align-items: center;

    .navbar-menu-item {
      a {
        text-decoration: none;
        padding: 10px;
        font-size: 1em;
        font-weight: 400;
      }

      a:hover {
        background: #f3f3f3;
        border-radius: 10px;
      }
    }

    .logout {
      cursor: pointer;
      padding: 0px 10px;
    }

    .logout:hover {
      color: var(--red-color);
    }
  }
}

@media screen and (max-width: 500px) {}