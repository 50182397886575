.edit-infos-form {
  .edit-infos-form-row {
    display: flex;
    flex-flow: wrap;
    gap: 10px;
  }

  .edit-infos-form-item {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;

    label {
      margin-bottom: 5px;
    }

    input {
      border: none;
      padding: 10px;
      font-size: 1em;
      border-radius: 10px;
      outline-color: var(--purple-color);
      border: 1px solid var(--border-color);
    }

    input:hover {
      background-color: var(--input-hover-color);
    }
  }
}

.input-error {
  padding: 5px;
  font-size: 0.8em;
  color: var(--red-color);
}

.submit-btn {
  background-color: var(--purple-color);
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
  color: white;
}

.submit-btn:hover {
  opacity: 0.9;
}

@media screen and (max-width: 500px) {
  .edit-infos-form-row {
    gap: 0px !important;
  }

  .submit-btn {
    margin-bottom: 10px;
  }
}